export const FOOTER_NAV = [
  {
    name: 'news',
    href_en: 'https://www.victoryarena.com/en/news',
    href_ar: 'https://www.victoryarena.com/ar/news',
  },
  {
    name: 'seating',
    href_en: 'https://www.victoryarena.com/en/seating',
    href_ar:
      'https://www.victoryarena.com/ar/%D9%85%D9%86%D8%A7%D8%B7%D9%82-%D8%A7%D9%84%D8%AC%D9%84%D9%88%D8%B3',
  },
  {
    name: 'routes',
    href_en: 'https://www.victoryarena.com/en/routes',
    href_ar: 'https://www.victoryarena.com/ar/%D8%A7%D9%84%D8%B7%D8%B1%D9%82',
  },
  {
    name: 'parking',
    href_en: 'https://www.victoryarena.com/en/parking',
    href_ar: 'https://www.victoryarena.com/ar/parking',
  },
  {
    name: 'faq',
    href_en: 'https://www.victoryarena.com/en/frequently-asked-questions',
    href_ar: 'https://www.victoryarena.com/ar/frequently-asked-questions',
  },
  {
    name: 'contact_us',
    href_en: 'https://www.victoryarena.com/ar/support',
    href_ar: 'https://www.victoryarena.com/en/support',
  },
];

export const FOOTER_SUB_NAV = [
  {
    name: 'cookie_policy',
    href_en: 'https://www.victoryarena.com/en/cookie-policy',
    href_ar:
      'https://www.victoryarena.com/ar/%D8%A7%D9%84%D8%B4%D8%B1%D9%88%D8%B7-%D9%88%D8%A7%D9%84%D8%A3%D8%AD%D9%83%D8%A7%D9%85-%D9%84%D8%A7%D8%B3%D8%AA%D8%AE%D8%AF%D8%A7%D9%85-%D9%85%D9%84%D9%81%D8%A7%D8%AA-%D8%A7%D9%84%D8%A7%D8%B1%D8%AA%D8%A8%D8%A7%D8%B7',
  },
  {
    name: 'terms_and_conditions',
    href_en: 'https://www.victoryarena.com/en/ticketing-terms-and-conditions',
    href_ar:
      'https://www.victoryarena.com/ar/%D8%B4%D8%B1%D9%88%D8%B7-%D9%88%D8%A3%D8%AD%D9%83%D8%A7%D9%85-%D8%A7%D9%84%D8%AA%D8%B0%D8%A7%D9%83%D8%B1',
  },
  {
    name: 'ticketing_terms_and_conditions',
    href_en: 'https://www.victoryarena.com/en/ticketing-terms-and-conditions',
    href_ar:
      'https://www.victoryarena.com/ar/%D8%B4%D8%B1%D9%88%D8%B7-%D9%88%D8%A3%D8%AD%D9%83%D8%A7%D9%85-%D8%A7%D9%84%D8%AA%D8%B0%D8%A7%D9%83%D8%B1',
  },
  {
    name: 'subscribe_newsletter',
    href_en: 'https://www.victoryarena.com/en/newsletter-signup',
    href_ar: 'https://www.victoryarena.com/ar/newsletter-signup',
  },
  {
    name: 'privacy_policy',
    href_en: 'https://www.victoryarena.com/en/privacy-policy',
    href_ar:
      'https://www.victoryarena.com/ar/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9',
  },
];

export const FOOTER_CONTACT_US = [
  {
    name: 'location',
    href: '',
    icon: '/images/footer/location-white.png',
  },
];
