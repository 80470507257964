export const SOCIAL_MEDIA = [
  // {
  //   name: 'discord',
  //   en: 'https://www.discord.com',
  //   ar: 'https://www.discord.com',
  //   icon: '/icons/social/discord.svg',
  // },
  // {
  //   name: 'twitch',
  // fr : 'https://www.twitch.com',
  //   en: 'https://www.twitch.com',
  //   ar: 'https://www.twitch.com',
  //   icon: '/icons/social/twitch.svg',
  // },
  {
    name: 'twitter',
    en: 'https://x.com/VictoryArena_sa',
    ar: 'https://x.com/VictoryArena_sa',
    icon: '/icons/social/twitter.svg',
  },
  {
    name: 'instagram',
    en: 'https://instagram.com/VictoryArena_sa',
    ar: 'https://instagram.com/VictoryArena_sa',
    icon: '/icons/social/instagram.svg',
  },
  {
    name: 'youtube',
    en: 'https://youtube.com/channel/UCEaH8Jj5UJPqTV7tVDLgdQw',
    ar: 'https://youtube.com/channel/UCEaH8Jj5UJPqTV7tVDLgdQw',
    icon: '/icons/social/youtube.svg',
  },
  // {
  //   name: 'facebook',
  //   en: 'https://www.facebook.com/VictoryArena_sa',
  //   ar: 'https://www.facebook.com/VictoryArena_sa',
  //   icon: '/icons/social/fb.svg',
  // },
  {
    name: 'snapchat',
    en: 'https://snapchat.com/add/VictoryArena_sa',
    ar: 'https://snapchat.com/add/VictoryArena_sa',
    icon: '/icons/social/snapchat.svg',
  },
];
