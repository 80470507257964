import { SocialProfileJsonLd } from 'next-seo';

export default function SocialJsonLd() {
  return (
    <SocialProfileJsonLd
      type='Organization'
      name='Alawwal Park'
      url='https://tickets.victoryarena.com/'
      sameAs={[
        'https://x.com/VictoryArena_sa',
        'https://instagram.com/VictoryArena_sa',
        'https://snapchat.com/add/VictoryArena_sa',
        'https://youtube.com/channel/UCEaH8Jj5UJPqTV7tVDLgdQw',
      ]}
    />
  );
}
