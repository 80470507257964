import { useDeviceDetect } from '@wbk/hooks';

type Props = {
  children: React.ReactNode;
};

const HideOnWebview = ({ children }: Props) => {
  const { isWebView } = useDeviceDetect();

  if (isWebView) return null;

  return children;
};

export default HideOnWebview;
