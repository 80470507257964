import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { GLOBAL_OPENER_DELAY } from '@/src/constants/delay';

const InitialLogoOpener = () => {
  const [mount, setMount] = useState(true);

  useEffect(() => {
    setMount(false);
  }, []);

  return (
    <AnimatePresence initial={false}>
      {mount && (
        <motion.div className='fixed inset-0 z-[9999] flex items-center justify-center text-logo'>
          <motion.svg
            viewBox='0 0 41 37'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='z-10 mx-8 max-w-md overflow-visible'
          >
            <motion.path
              stroke='currentColor'
              strokeWidth={2}
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 0 }}
              exit={['draw', 'bounce']}
              variants={{
                draw: { pathLength: 1, transition: { duration: 1 } },
                bounce: { scale: [1, 0.5, 10], transition: { duration: 0.6, delay: 0.9 } },
              }}
              d='M20.1068 5.99297L20.6071 6.28204L21.1074 5.99295L29.48 1.15495L40.2133 7.35711V19.764L20.6496 31.1139L10.8255 25.4388L1 19.7628V7.3571L11.7334 1.15493L20.1068 5.99297Z'
            />
          </motion.svg>
          <motion.div
            className='absolute h-[5000px] w-[5000px] rounded-full border-secondary'
            initial={{ borderWidth: 2500 }}
            animate={{ borderWidth: 2500 }}
            exit={{ borderWidth: 0 }}
            transition={{ duration: 0.5, delay: GLOBAL_OPENER_DELAY }}
          ></motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default InitialLogoOpener;
