import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '@wbk/svg';

const messages: {
  text: {
    en: string;
    ar: string;
  };
  image?: string;
}[] = [
  {
    text: {
      en: 'Get 15% off on Seasonal Memberships by using SAB Visa/Mastercard',
      ar: 'احصل على خصم ١٥٪ على العضويات الموسمية عند استخدام بطاقات فيزا/ماستر كارد من البنك السعودي الأول',
    },
    image: '/images/SAB.png',
  },
];

const TopNotice = () => {
  const [dismissed, setDismissed] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const { locale: routerLocale = 'en' } = useRouter();
  const locale = routerLocale === 'en' || routerLocale === 'ar' ? routerLocale : 'en'; // Default to 'en' if locale is undefined or not 'en'/'ar'

  useEffect(() => {
    if (!messages.length) return;

    const interval = setInterval(() => {
      let nextIndex = currentCardIndex + 1;
      if (nextIndex >= messages.length) {
        nextIndex = 0;
      }
      setCurrentCardIndex(nextIndex);
    }, 8000);

    return () => clearInterval(interval);
  }, [currentCardIndex]);

  if (!messages.length || dismissed) return null;

  return (
    <AnimatePresence mode='popLayout' initial={false}>
      <motion.div
        key={locale}
        className='relative z-40 flex justify-center overflow-hidden bg-primary'
        initial={{ y: 35 }}
        animate={{ y: 0 }}
        exit={{ y: -35 }}
        transition={{ duration: 0.3 }}
      >
        <div className='flex w-full justify-center px-4 py-1.5'>
          <div className='flex w-full max-w-5xl items-center justify-center gap-2'>
            {messages[currentCardIndex].image && (
              <Image
                src={messages[currentCardIndex].image as string}
                width={75}
                height={50}
                className='min-w-14 rounded-md bg-white'
                alt=''
              />
            )}
            <p className='text-wrap text-sm text-primary-contrast md:text-center md:text-base ltr:pr-7 ltr:lg:pr-0 rtl:pl-7 rtl:lg:pl-0'>
              {messages[currentCardIndex].text[locale]}
            </p>
          </div>
        </div>

        <button
          data-testid='top_notice_close'
          onClick={() => setDismissed(true)}
          type='button'
          className='absolute top-1/2 flex h-6 w-6 -translate-y-1/2 items-center justify-center rounded-full bg-primary-contrast/20 p-1 hover:opacity-70 ltr:right-2 rtl:left-2'
        >
          <CloseIcon className='text-primary-contrast' />
        </button>
      </motion.div>
    </AnimatePresence>
  );
};

export default TopNotice;
