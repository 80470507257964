export const HEADER_NAV = [
  {
    title: 'news',
    href_en: 'https://www.victoryarena.com/en/news',
    href_ar: 'https://www.victoryarena.com/ar/news',
    external: true,
  },
  {
    title: 'seating',
    href_en: 'https://www.victoryarena.com/en/seating',
    href_ar:
      'https://www.victoryarena.com/ar/%D9%85%D9%86%D8%A7%D8%B7%D9%82-%D8%A7%D9%84%D8%AC%D9%84%D9%88%D8%B3',
    external: true,
  },
  {
    title: 'routes',
    href_en: 'https://www.victoryarena.com/en/routes',
    href_ar: 'https://www.victoryarena.com/ar/%D8%A7%D9%84%D8%B7%D8%B1%D9%82',
    external: true,
  },
  {
    title: 'parking',
    href_en: 'https://www.victoryarena.com/en/parking',
    href_ar: 'https://www.victoryarena.com/ar/parking',
    external: true,
  },
  {
    title: 'faq',
    href_en: 'https://www.victoryarena.com/en/frequently-asked-questions',
    href_ar: 'https://www.victoryarena.com/ar/frequently-asked-questions',
    external: true,
  },
  {
    title: 'contact_us',
    href_en: 'https://www.victoryarena.com/ar/support',
    href_ar: 'https://www.victoryarena.com/en/support',
    external: true,
  },
];
