import { useTranslation } from 'next-i18next';
import { WbkLogo } from '@wbk/svg';
import { useRouter } from 'next/router';
import Link from 'next/link';

const Copyright = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  return (
    <div className='flex flex-wrap items-center justify-center gap-2 sm:justify-start'>
      <span className='text-center text-xs uppercase text-white'>
        {t('common:footer.rights', { year: new Date().getFullYear() })}
      </span>
      <div className='flex items-center gap-3'>
        <span className='text-xs text-white'>{t('common:footer.powered')}</span>
        <Link
          href={`https://webook.com/${locale}/business`}
          target='_blank'
          rel='noreferrer'
          className='transition hover:scale-110'
        >
          <WbkLogo width={50} height={30} />
        </Link>
      </div>
    </div>
  );
};

export default Copyright;
