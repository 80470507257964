import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

type Props = {
  grayish?: boolean;
  onlyLogos?: boolean;
  withTabby?: boolean;
};

const LOGOS = [
  { src: '/icons/payments/mada.svg', alt: 'Mada Payment Logo' },
  { src: '/icons/payments/apple-pay.svg', alt: 'Apple Payment Logo Pay' },
  { src: '/icons/payments/stc-pay.svg', alt: 'STC Payment Logo Pay' },
  { src: '/icons/payments/visa.svg', alt: 'Visa Payment Logo' },
  { src: '/icons/payments/mastercard.svg', alt: 'Mastercard Payment Logo' },
  { src: '/icons/payments/american-express.svg', alt: 'American Payment Logo Express' },
  { src: '/icons/payments/tabby.svg', alt: 'Tabby Payment Logo' },
  { src: '/icons/payments/ssl-secure.svg', alt: 'Payment is Secure Logo' },
];

const PaymentMethods = ({ grayish, onlyLogos, withTabby = true }: Props) => {
  const { t } = useTranslation();

  const filteredLogos = useMemo(() => {
    if (withTabby) {
      return LOGOS.filter((logo) => !logo.src.includes('ssl-secure'));
    }
    return LOGOS.filter((logo) => !logo.src.includes('tabby'));
  }, [withTabby]);

  if (onlyLogos) {
    return (
      <div className='flex items-center justify-center space-x-4 rtl:space-x-reverse'>
        {filteredLogos.map((logo, i) => (
          <div key={i} className={`relative h-6 w-12 ${grayish ? 'opacity-50 grayscale' : ''}`}>
            <Image src={logo.src} fill sizes='100%' alt={logo.alt} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div
      className={`my-5' flex flex-wrap items-center justify-center ${grayish ? '' : 'rounded-xl border border-t-0 border-gray-400'}`}
    >
      {!grayish && (
        <div className='flex w-full items-center justify-between'>
          <div className='mt-0 h-5 w-full border-t border-gray-400 ltr:rounded-l-xl rtl:rounded-r-xl'></div>
          <p className='-mt-4 shrink-0 grow whitespace-nowrap px-4 text-center text-xs text-gray-400'>
            {t('common:guaranteed_payment')}
          </p>
          <div className='mt-0 h-5 w-full border-t border-gray-400 ltr:rounded-r-xl rtl:rounded-l-xl'></div>
        </div>
      )}
      <div className='mt-2 flex w-full items-center justify-center space-x-4 px-4 pb-4 rtl:space-x-reverse'>
        {filteredLogos.map((logo, i) => (
          <div key={i} className={`relative h-6 w-12 ${grayish ? 'opacity-50 grayscale' : ''}`}>
            <Image src={logo.src} fill sizes='100%' alt={logo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default PaymentMethods;
