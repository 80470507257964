import MainFooter from './Main';
import MiniFooter from './Mini';

type Props = {
  settings: LayoutProps['footer'];
};

const Footer = ({ settings }: Props) => {
  return settings?.type === 'main' ? <MainFooter /> : <MiniFooter />;
};

Footer.defaultProps = {
  settings: {
    type: 'main',
  },
};

export default Footer;
