import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { SOCIAL_MEDIA } from '@/src/constants/social';
import PaymentMethods from '@/components/common/PaymentMethods';
import Copyright from './Copyright';
import { FOOTER_CONTACT_US, FOOTER_NAV, FOOTER_SUB_NAV } from './nav';

const MainFooter = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <footer className='bg-[#000E22] text-base'>
      <div className='container flex flex-col gap-5 py-10 lg:flex-row lg:py-20'>
        <div className='flex-1 space-y-5 lg:space-y-10'>
          <div className='flex items-center gap-5'>
            <Image
              className='rtl:hidden'
              src='/icons/logo-en.svg'
              width={163}
              height={83}
              alt={t('common:seo.title')}
            />
            <Image
              className='ltr:hidden'
              src='/icons/logo-ar.svg'
              width={163}
              height={83}
              alt={t('common:seo.title')}
            />

            <Image src='/images/SMC-new.png' width={125} height={45} alt='SMC logo' />
          </div>
          <p className='text-gray-400'>{t('common:footer.e_tickets_description')}</p>
          <PaymentMethods withTabby />
        </div>

        <nav className='flex flex-1 flex-col items-start lg:items-center'>
          <div>
            <div className='mb-5 font-bold uppercase text-white'>
              {t('common:footer.know_more')}
            </div>
            <ul className='flex flex-wrap gap-5 text-start text-sm lg:grid lg:grid-cols-1'>
              {FOOTER_NAV.map((item, i) => (
                <li key={i}>
                  <a
                    className='text-gray-400 hover:text-white hover:underline'
                    href={item[`href_${locale || 'en'}` as 'href_en'] || item.href_en}
                    target='_blank'
                    rel='noreferrer noopener'
                    data-location='footer'
                    data-category={item.name}
                  >
                    {t(`common:nav.${item.name}`)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>

        <div className='flex-1 space-y-5 lg:space-y-10'>
          <div className='space-y-5'>
            <div className='font-bold uppercase text-white'>{t('common:footer.follow_us')}</div>
            <ul className='flex flex-wrap gap-2'>
              {SOCIAL_MEDIA.map((item, i) => (
                <li key={i}>
                  <a
                    href={item[locale as 'en'] || item.en}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='block rounded-lg border p-2 opacity-70 hover:opacity-100'
                    data-location='footer'
                    data-category={item.name}
                  >
                    <Image width={20} height={20} src={item.icon} alt={item.name} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className='space-y-5'>
            <div className='font-bold uppercase text-white'>{t('common:nav.location_title')}</div>
            <ul className='grid grid-cols-1 gap-5 text-sm'>
              {FOOTER_CONTACT_US.map((item, i) => (
                <li key={i} className='flex items-center gap-2'>
                  <Image width={15} height={15} src={item.icon} alt={item.name} />
                  <p
                    className='text-gray-400'
                    dir='ltr'
                    data-location='footer'
                    data-category={item.name}
                  >
                    {t(`common:nav.${item.name}`)}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* poweredBy footer etc.. */}
      <div className='bg-[#000A19] py-5'>
        <div className='container flex flex-col justify-between space-y-5 lg:flex-row lg:space-y-0'>
          <Copyright />
          <ul className='flex flex-col gap-2 text-start text-sm lg:flex-row lg:gap-5'>
            {FOOTER_SUB_NAV.map((item, i) => (
              <li key={i}>
                <a
                  className='text-gray-400 hover:text-white hover:underline'
                  href={item[`href_${locale || 'en'}` as 'href_en'] || item.href_en}
                  target='_blank'
                  rel='noreferrer noopener'
                  data-location='footer'
                  data-category={item.name}
                >
                  {t(`common:nav.${item.name}`)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
