import { useEffect, useState } from 'react';
import { useAuth } from '@wbk/auth';

type Props = {
  children: React.ReactNode;
};

const HideOnTawakkalna = ({ children }: Props) => {
  const { isTawakkalnaUser } = useAuth();
  const [isTWK, setIsTWK] = useState(false);

  useEffect(() => {
    setIsTWK(isTawakkalnaUser);
  }, [isTawakkalnaUser]);

  if (isTWK) {
    return null;
  }

  return <>{children}</>;
};

export default HideOnTawakkalna;
